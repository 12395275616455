export enum FeatureFlags {
  ShowPremium20Web = 'showPremium20Web',
  ShowPremium21Web = 'showPremium21Web',
  ShowRedesignedProfileWeb = 'showRedesignedProfileWeb',
  ShowNewPortfolioWeb = 'showNewPortfolioWeb',
  ShowNewPortfolioWebPart2 = 'showNewPortfolioWebPart2',
  ShowOnboardingWebBannerV2 = 'showOnboardingWebBannerV2',
  ShowPremiumMyReturns = 'showPremiumMyReturns',
  ShowLoanActivityTabWeb = 'showLoanActivityTabWeb',
  ShowNewContractDocumentationWeb = 'showNewContractDocumentationWeb',
  ShowMultiplePartnersWeb = 'showMultiplePartnersWeb',
}

export const FallbackFeatureFlagValues = {
  [FeatureFlags.ShowPremium20Web]: false,
  [FeatureFlags.ShowPremium21Web]: false,
  [FeatureFlags.ShowRedesignedProfileWeb]: false,
  [FeatureFlags.ShowNewPortfolioWeb]: false,
  [FeatureFlags.ShowNewPortfolioWebPart2]: false,
  [FeatureFlags.ShowOnboardingWebBannerV2]: false,
  [FeatureFlags.ShowPremiumMyReturns]: false,
  [FeatureFlags.ShowLoanActivityTabWeb]: false,
  [FeatureFlags.ShowNewContractDocumentationWeb]: false,
  [FeatureFlags.ShowMultiplePartnersWeb]: false,
};
