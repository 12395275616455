import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A date string, such as 2018-07-01, serialized in ISO8601 format */
  LocalDate: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  city: Scalars['String'];
  zip: Scalars['String'];
  countryIsoCode: Scalars['String'];
};

/** Amount with currency */
export type AmountWithCurrencyObjectType = {
  __typename?: 'AmountWithCurrencyObjectType';
  amount: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type AverageYieldObjectType = {
  __typename?: 'AverageYieldObjectType';
  /** Current average yield from all investments */
  averageYield: Scalars['Float'];
  year: Scalars['Int'];
  /** Month in range from 0 to 11 */
  month: Scalars['Int'];
};

export enum BaseOperator {
  Or = 'OR',
  And = 'AND',
}

export type BasicUserInfoObjectType = {
  __typename?: 'BasicUserInfoObjectType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
};

/** Current and expected yield statistics for actual month */
export type CurrentAndExpectedYieldStatisticsForActualMonthObjectType = {
  __typename?: 'CurrentAndExpectedYieldStatisticsForActualMonthObjectType';
  /** Sum of all expected yield in actual month */
  expectedYield: Scalars['Float'];
  /** Sum of all repaid yield in actual month */
  repaidYield: Scalars['Float'];
  /** Count of already repaid projects in actual month */
  repaidProjectsCount: Scalars['Float'];
};

/** Monetary amount value and currency */
export type FloatMonetaryAmountObjectType = {
  __typename?: 'FloatMonetaryAmountObjectType';
  value: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type InterestSumValues = {
  __typename?: 'InterestSumValues';
  settled: FloatMonetaryAmountObjectType;
  planned: FloatMonetaryAmountObjectType;
  overdue: FloatMonetaryAmountObjectType;
};

export type InvestedPropertiesFilterType = {
  repaymentStatus?: Maybe<PropertyInvestmentRoundRepaymentStatus>;
  repaymentStatuses?: Maybe<Array<PropertyInvestmentRoundRepaymentStatus>>;
  investmentRoundStatuses?: Maybe<Array<PropertyInvestmentRoundStatus>>;
};

/** Describes user's currently held investment in a property. */
export type InvestedPropertyObjectType = {
  __typename?: 'InvestedPropertyObjectType';
  /** Property id */
  id: Scalars['ID'];
  /** Property investment round id */
  propertyInvestmentRoundId: Scalars['ID'];
  investmentRoundType: PropertyInvestmentRoundType;
  investmentRoundStatus: PropertyInvestmentRoundStatus;
  investmentRoundLegacyEndedAt?: Maybe<Scalars['Timestamp']>;
  investmentRoundRepaymentStatus: PropertyInvestmentRoundRepaymentStatus;
  slug: Scalars['String'];
  /** Translated property name */
  name: Scalars['String'];
  countryIsoCode: Scalars['String'];
  city: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  /** Property primary photo */
  photo: PropertyPhotoObjectType;
  /** User's total investment in this property investment round */
  investmentAmount: Scalars['Float'];
  /** Investment round currency */
  investmentCurrency: Scalars['String'];
  annualPercentageYield: Scalars['Float'];
  /** Defines whether user can apply their current level to gain bonus yield. */
  bonusYieldEligible: Scalars['Boolean'];
  investmentLengthInMonths: Scalars['Int'];
  /** Remaining time to the end of the round in seconds. Approximated for not yet funded rounds. */
  timeLeft: Scalars['Float'];
  /** When are the investors paid back. Is set when round is set as Funded. */
  investmentTermEnd?: Maybe<Scalars['Timestamp']>;
  interestAmount: FloatMonetaryAmountObjectType;
  /** Due date of oldest unpaid installment in format YYYY-MM-DD. */
  oldestUnpaidInstallmentDate?: Maybe<Scalars['LocalDate']>;
  /** Due date of oldest unpaid installment in format YYYY-MM-DD with gracePeriod added. */
  oldestUnpaidInstallmentDateWithGracePeriod?: Maybe<Scalars['LocalDate']>;
  riskCategory: RiskCategory;
  level: PropertyInvestmentRoundLevel;
};

/** Statistics of yield paid to the user in the invested property */
export type InvestedPropertyRoundRepaymentStatisticsObjectType = {
  __typename?: 'InvestedPropertyRoundRepaymentStatisticsObjectType';
  /** Yield currently paid to user */
  currentYield: Scalars['Float'];
  /** Expected yield to be paid to user */
  expectedYield: Scalars['Float'];
};

/** Describes user's investment transaction. */
export type InvestmentObjectType = {
  __typename?: 'InvestmentObjectType';
  /** Identifier of the investment */
  id: Scalars['ID'];
  /** When was the investment made */
  occurredAt: Scalars['DateTime'];
  /** Transaction amount */
  amount: AmountWithCurrencyObjectType;
  /** Whether withdrawal from this investment can be made. */
  canBeWithdrawn: Scalars['Boolean'];
  /** Whether investment was already withdrawn. */
  withdrawn: Scalars['Boolean'];
};

export type InvestmentRoundSecondaryMarketInfo = Node & {
  __typename?: 'InvestmentRoundSecondaryMarketInfo';
  /** Primary identifier */
  id: Scalars['ID'];
  property: SecondaryMarketItemPropertyInfo;
  /** Number of investors in investment round */
  numberOfInvestors: Scalars['Int'];
  /** Number of secondary market offers for the round */
  offersCount: Scalars['Int'];
  /** Sum of remainingAmount attribute of all related secondary market offers. */
  remainingAmountsSum: AmountWithCurrencyObjectType;
  /** Remaining time to the end of the round in seconds */
  timeLeft: Scalars['Float'];
  /** Annual percentage yield (e.g. 0.045 = 4,5 %) */
  annualPercentageYield: Scalars['Float'];
  /** Defines whether user can apply their current level to gain bonus yield. */
  bonusYieldEligible: Scalars['Boolean'];
  level: SecondaryMarketItemLevel;
  repaymentStatus: PropertyInvestmentRoundRepaymentStatus;
  /** Total investment made by the currently signed in user in this investment round. Same currency as `remainingAmountsSum.currency` */
  currentUsersTotalInvestment: Scalars['Float'];
  /** Loan To Value refers to the ratio between the loan amount and the collateral value stated as a percentage. */
  loanToValue: Scalars['Float'];
  riskCategory: RiskCategory;
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  count: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

/** Portfolio size in a respective month in history or present */
export type MonthlyPortfolioSizeObjectType = {
  __typename?: 'MonthlyPortfolioSizeObjectType';
  year: Scalars['Int'];
  month: Scalars['Int'];
  /** Portfolio size expressed in a specific currency */
  portfolioSize: AmountWithCurrencyObjectType;
};

export type Node = {
  id: Scalars['ID'];
};

/** Portfolio statistics that have something in common (e.g. the same repayment status). */
export type PortfolioPartStatisticsObjectType = {
  __typename?: 'PortfolioPartStatisticsObjectType';
  /** Sum of all current investments */
  investedAmount: Scalars['Float'];
  /** Count of invested properties */
  propertiesCount: Scalars['Int'];
};

/** Statistics about user's portfolio. */
export type PortfolioStatisticsObjectType = {
  __typename?: 'PortfolioStatisticsObjectType';
  /** Sum of all current investments */
  portfolioSize: Scalars['Float'];
  /** Count of invested properties */
  propertiesCount: Scalars['Int'];
  /** Count of active invested properties */
  activePropertiesCount: Scalars['Int'];
  /** Count of cities that user invested in through properties */
  citiesCount: Scalars['Int'];
  /** Expected profit at the end of all current user investments */
  expectedProfit: Scalars['Float'];
  /** Statistics for each repayment status */
  repaymentStatistics: RepaymentStatisticsObjectType;
  /** Current and expected yield statistics for actual month */
  currentAndExpectedYieldStatisticsForActualMonth: CurrentAndExpectedYieldStatisticsForActualMonthObjectType;
  /** Current average yield from all investments */
  currentAverageYield: Scalars['Float'];
  /** Current average discount rate from all investments */
  currentAverageDiscountedInterestRate?: Maybe<Scalars['Float']>;
};

export type PropertyBaseObjectType = Node & {
  __typename?: 'PropertyBaseObjectType';
  id: Scalars['ID'];
  status: PropertyStatus;
  slug: Scalars['String'];
  /** Translated property name */
  name: Scalars['String'];
  /** Translated property description */
  description: Scalars['String'];
  /** Translated property type */
  propertyType: Scalars['String'];
  city: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  countryIsoCode: Scalars['String'];
  /** Translated property layout */
  propertyLayout: Scalars['String'];
  /** Floor space in m2 */
  floorSpace: Scalars['Float'];
  currentInvestmentRound?: Maybe<PropertyInvestmentRoundBaseObjectType>;
  investmentRound: PropertyInvestmentRoundBaseObjectType;
  /** Property currently has a tenant */
  occupied: Scalars['Boolean'];
  photos: Array<PropertyPhotoObjectType>;
};

export type PropertyBorrower = {
  __typename?: 'PropertyBorrower';
  /** Name of the company that is going to borrow funds. */
  companyName: Scalars['String'];
  /** Additional info mainly about the company. It contains markdown. */
  description: Scalars['String'];
  /** Official identifier of the company in the Register (IČO). */
  companyIdentifier: Scalars['String'];
  /** Text about the entry in the Commercial Register. */
  registerEntry?: Maybe<Scalars['String']>;
  /** Structured address of the company. */
  address: Address;
};

export type PropertyFinancialAnalysisObjectType = {
  __typename?: 'PropertyFinancialAnalysisObjectType';
  description?: Maybe<Scalars['String']>;
  financialData: Array<PropertyFinancialDataYearObjectType>;
};

export type PropertyFinancialDataObjectType = {
  __typename?: 'PropertyFinancialDataObjectType';
  metricName: Scalars['String'];
  metricValue: Scalars['Float'];
  /** Supported values: CZK, % */
  unit?: Maybe<Scalars['String']>;
};

export type PropertyFinancialDataYearObjectType = {
  __typename?: 'PropertyFinancialDataYearObjectType';
  year: Scalars['Int'];
  values: Array<PropertyFinancialDataObjectType>;
};

export type PropertyInvestmentRoundActivityDescriptionObjectType = {
  __typename?: 'PropertyInvestmentRoundActivityDescriptionObjectType';
  lang: Scalars['String'];
  description: Scalars['String'];
};

export type PropertyInvestmentRoundActivityFilterType = {
  includeInstallments?: Maybe<Scalars['Boolean']>;
};

export type PropertyInvestmentRoundActivityObjectType = Node & {
  __typename?: 'PropertyInvestmentRoundActivityObjectType';
  id: Scalars['ID'];
  source: PropertyInvestmentRoundActivitySource;
  type: PropertyInvestmentRoundActivityType;
  occuredAt: Scalars['Timestamp'];
  occurredAt: Scalars['Timestamp'];
  /** Translations as a lang and description objects */
  description: Array<PropertyInvestmentRoundActivityDescriptionObjectType>;
};

export enum PropertyInvestmentRoundActivitySource {
  InvestownActivity = 'InvestownActivity',
}

export enum PropertyInvestmentRoundActivityType {
  Positive = 'Positive',
  Negative = 'Negative',
  Info = 'Info',
}

export type PropertyInvestmentRoundBaseObjectType = Node & {
  __typename?: 'PropertyInvestmentRoundBaseObjectType';
  id: Scalars['ID'];
  legalId?: Maybe<Scalars['String']>;
  type: PropertyInvestmentRoundType;
  status: PropertyInvestmentRoundStatus;
  contractVersionNumber: Scalars['Int'];
  /** Total investment amount (cost of the property, e.g. 5 mil. CZK) */
  investmentAmountTotal: Scalars['Float'];
  /** Amount that has been already distributed to users (e.g. because of partial early repayment). Defaults to 0. */
  partiallyDistributedAmount: Scalars['Float'];
  /** Currently available amount for investment (e.g. 100k CZK) */
  investmentAmountAvailable: Scalars['Float'];
  /** Currency ISO code (e.g. "CZK") */
  investmentCurrency: Scalars['String'];
  /** Total investment made by the currently signed in user in this investment round. Currency is the same as `investmentCurrency`. */
  currentUsersTotalInvestment: Scalars['Float'];
  investmentLengthInMonths: Scalars['Int'];
  /** When can be the LAST investment made. */
  availableForInvestmentTo: Scalars['Timestamp'];
  /** When are the investors paid back */
  investmentTermEnd?: Maybe<Scalars['Timestamp']>;
  /** Maximum sum of all investments a user can hold in this round (e.g. 300 000) */
  limitPerUser: Scalars['Float'];
  /** Annual percentage yield (e.g. 0.045 = 4,5 %) */
  annualPercentageYield: Scalars['Float'];
  /** Number of investors in investment round */
  numberOfInvestors: Scalars['Int'];
  /** Percentage bonus that is paid off at the end of the investment round to all current investment holders. */
  endPercentageBonus: Scalars['Float'];
  /** Annual percentage yield that is adjusted by `endPercentageBonus` that is weighted by length of the whole investment round. */
  adjustedYield: Scalars['Float'];
  /** Defines whether user can apply their current level to gain bonus yield. */
  bonusYieldEligible: Scalars['Boolean'];
  level: PropertyInvestmentRoundLevel;
  borrower: PropertyBorrower;
  borrowers: Array<PropertyBorrower>;
  repaymentStatus: PropertyInvestmentRoundRepaymentStatus;
  /** Loan To Value refers to the ratio between the loan amount and the collateral value stated as a percentage. */
  loanToValue?: Maybe<Scalars['Float']>;
  legacyEndedAt?: Maybe<Scalars['Timestamp']>;
  riskCategory: RiskCategory;
  activeSecondaryMarketOffers: Array<SecondaryMarketOffer>;
};

export type PropertyInvestmentRoundFees = {
  __typename?: 'PropertyInvestmentRoundFees';
  /** Total interest values grouped by their status: settled, planned and overdue. */
  interestSumValues?: Maybe<InterestSumValues>;
  entryFee: Scalars['Float'];
  /** Percentage of total amount that the platform takes for Investown (e.g. 0.02 = 2%) as fee. Total loan interest p.a. for partner = annualPercentageYieldForUsers + annualPercentageYieldPlatformFee. */
  monthlyYieldPlatformFee: Scalars['Float'];
  /** Discount rate */
  discountedInterestRate: Scalars['Float'];
};

export enum PropertyInvestmentRoundLevel {
  Standard = 'Standard',
  Premium = 'Premium',
  Vip = 'Vip',
}

export type PropertyInvestmentRoundObjectType = Node & {
  __typename?: 'PropertyInvestmentRoundObjectType';
  id: Scalars['ID'];
  legalId?: Maybe<Scalars['String']>;
  type: PropertyInvestmentRoundType;
  status: PropertyInvestmentRoundStatus;
  contractVersionNumber: Scalars['Int'];
  /** Total investment amount (cost of the property, e.g. 5 mil. CZK) */
  investmentAmountTotal: Scalars['Float'];
  /** Amount that has been already distributed to users (e.g. because of partial early repayment). Defaults to 0. */
  partiallyDistributedAmount: Scalars['Float'];
  /** Principal amount that needs to be repaid (e.g. because of partial early repayment). */
  investmentAmountToBeRepaid: Scalars['Float'];
  /** Currently available amount for investment (e.g. 100k CZK) */
  investmentAmountAvailable: Scalars['Float'];
  /** Currency ISO code (e.g. "CZK") */
  investmentCurrency: Scalars['String'];
  /** Total investment made by the currently signed in user in this investment round. Currency is the same as `investmentCurrency`. */
  currentUsersTotalInvestment: Scalars['Float'];
  investmentLengthInMonths: Scalars['Int'];
  /** When can be the LAST investment made. */
  availableForInvestmentTo: Scalars['Timestamp'];
  /** When are the investors paid back */
  investmentTermEnd?: Maybe<Scalars['Timestamp']>;
  /** Maximum sum of all investments a user can hold in this round (e.g. 300 000) */
  limitPerUser: Scalars['Float'];
  /** Annual percentage yield (e.g. 0.045 = 4,5 %) */
  annualPercentageYield: Scalars['Float'];
  /** Number of investors in investment round */
  numberOfInvestors: Scalars['Int'];
  /** Percentage bonus that is paid off at the end of the investment round to all current investment holders. */
  endPercentageBonus: Scalars['Float'];
  /** Annual percentage yield that is adjusted by `endPercentageBonus` that is weighted by length of the whole investment round. */
  adjustedYield: Scalars['Float'];
  /** Defines whether user can apply their current level to gain bonus yield. */
  bonusYieldEligible: Scalars['Boolean'];
  level: PropertyInvestmentRoundLevel;
  borrower: PropertyBorrower;
  borrowers: Array<PropertyBorrower>;
  repaymentStatus: PropertyInvestmentRoundRepaymentStatus;
  /** Loan To Value refers to the ratio between the loan amount and the collateral value stated as a percentage. */
  loanToValue?: Maybe<Scalars['Float']>;
  legacyEndedAt?: Maybe<Scalars['Timestamp']>;
  riskCategory: RiskCategory;
  /** Minimum amount for every new investment to the round (e.g. 500) */
  minInvestment: Scalars['Float'];
  /** Multiple of the `annualPercentageYield` and the period until `investmentTermEnd` plus `endPercentageBonus`. */
  overallAdjustedYield: Scalars['Float'];
  purpose: PropertyInvestmentRoundPurpose;
  /** The value of the property that the investment is collateralised by. */
  collateral?: Maybe<Scalars['Float']>;
  activeSecondaryMarketOffers: Array<SecondaryMarketOffer>;
  activity: Array<PropertyInvestmentRoundActivityObjectType>;
};

export type PropertyInvestmentRoundObjectTypeActivityArgs = {
  filter?: Maybe<PropertyInvestmentRoundActivityFilterType>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type PropertyInvestmentRoundObjectTypeCondition = {
  operator?: Maybe<BaseOperator>;
  type_in?: Maybe<Array<PropertyInvestmentRoundType>>;
  status_in?: Maybe<Array<PropertyInvestmentRoundStatus>>;
  investmentAmountAvailable_eq?: Maybe<Scalars['Float']>;
  investmentAmountAvailable_gt?: Maybe<Scalars['Float']>;
  currentUsersTotalInvestment_gt?: Maybe<Scalars['Float']>;
  level_in?: Maybe<Array<PropertyInvestmentRoundLevel>>;
};

export type PropertyInvestmentRoundObjectTypeFilter = {
  operator?: Maybe<BaseOperator>;
  type_in?: Maybe<Array<PropertyInvestmentRoundType>>;
  status_in?: Maybe<Array<PropertyInvestmentRoundStatus>>;
  investmentAmountAvailable_eq?: Maybe<Scalars['Float']>;
  investmentAmountAvailable_gt?: Maybe<Scalars['Float']>;
  currentUsersTotalInvestment_gt?: Maybe<Scalars['Float']>;
  level_in?: Maybe<Array<PropertyInvestmentRoundLevel>>;
  conditions?: Maybe<Array<PropertyInvestmentRoundObjectTypeCondition>>;
};

export enum PropertyInvestmentRoundPurpose {
  Rental = 'Rental',
  Reconstruction = 'Reconstruction',
  PropertyAquisition = 'PropertyAquisition',
  NonPurposeLoan = 'NonPurposeLoan',
  Construction = 'Construction',
  Other = 'Other',
  BusinessDevelopment = 'BusinessDevelopment',
  Refinance = 'Refinance',
}

export enum PropertyInvestmentRoundRepaymentStatus {
  Regular = 'Regular',
  Delayed = 'Delayed',
  Collection = 'Collection',
  Unknown = 'Unknown',
}

export enum PropertyInvestmentRoundStatus {
  Unknown = 'Unknown',
  Open = 'Open',
  FullyInvested = 'FullyInvested',
  Funded = 'Funded',
  NotFunded = 'NotFunded',
  Ended = 'Ended',
}

export enum PropertyInvestmentRoundType {
  Legacy = 'Legacy',
  Crowdfunding = 'Crowdfunding',
}

export type PropertyObjectType = Node & {
  __typename?: 'PropertyObjectType';
  id: Scalars['ID'];
  status: PropertyStatus;
  slug: Scalars['String'];
  /** Translated property name */
  name: Scalars['String'];
  /** Translated property description */
  description: Scalars['String'];
  /** Translated property type */
  propertyType: Scalars['String'];
  city: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  countryIsoCode: Scalars['String'];
  /** Translated property layout */
  propertyLayout: Scalars['String'];
  /** Floor space in m2 */
  floorSpace: Scalars['Float'];
  /** Property currently has a tenant */
  occupied: Scalars['Boolean'];
  location: Location;
  currentInvestmentRound?: Maybe<PropertyInvestmentRoundObjectType>;
  investmentRound: PropertyInvestmentRoundObjectType;
  photos: Array<PropertyPhotoObjectType>;
};

export type PropertyPhotoObjectType = {
  __typename?: 'PropertyPhotoObjectType';
  id: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
  isPrimary: Scalars['Boolean'];
};

export enum PropertyStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
}

export type Query = {
  __typename?: 'Query';
  allProperties: Array<PropertyBaseObjectType>;
  _allPropertiesMeta: ListMetadata;
  propertyFinancialAnalysis?: Maybe<PropertyFinancialAnalysisObjectType>;
  property: PropertyObjectType;
  /** List of currently held investments of the signed in user. */
  investedProperties: Array<InvestedPropertyObjectType>;
  /** List of currently held investments of the signed in user. */
  investedPropertiesV2: Array<InvestedPropertyObjectType>;
  _investedPropertiesMeta: ListMetadata;
  investedPropertyRoundRepaymentStatistics: InvestedPropertyRoundRepaymentStatisticsObjectType;
  /** Statistics about user's portfolio. */
  portfolioStatistics: PortfolioStatisticsObjectType;
  /** Portfolio size in a respective month in history or present */
  portfolioSizeMonthlyHistory: Array<MonthlyPortfolioSizeObjectType>;
  /** How many months to the history has the user's portfolio existed (including current month) */
  _portfolioSizeMonthlyHistoryMeta: ListMetadata;
  /** Returns user's paged average yield history. */
  averageYieldMonthlyHistory: Array<AverageYieldObjectType>;
  _averageYieldMonthlyHistoryMeta: ListMetadata;
  /** Returns all open investments in the specified Crowdfunding investment round. */
  investments: Array<InvestmentObjectType>;
  /** Sums of active secondary market offers of the signed in user in the given investment round. */
  activeSecondaryMarketOffersSums: SecondaryMarketOffersSums;
  secondaryMarketItems: Array<SecondaryMarketItem>;
  investmentRoundsSecondaryMarketInfos: Array<InvestmentRoundSecondaryMarketInfo>;
  investmentRoundSecondaryMarketInfo?: Maybe<InvestmentRoundSecondaryMarketInfo>;
  _secondaryMarketItemsMeta: ListMetadata;
  _investmentRoundsSecondaryMarketInfosMeta: ListMetadata;
  secondaryMarketBids: Array<SecondaryMarketBid>;
  _secondaryMarketBidsMeta: ListMetadata;
  investmentRoundFees: PropertyInvestmentRoundFees;
  repaymentSchedule: RepaymentScheduleObjectType;
  relatedProperties: Array<RelatedPropertyObjectType>;
};

export type QueryAllPropertiesArgs = {
  currentInvestmentRoundFilter?: Maybe<PropertyInvestmentRoundObjectTypeFilter>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_AllPropertiesMetaArgs = {
  currentInvestmentRoundFilter?: Maybe<PropertyInvestmentRoundObjectTypeFilter>;
};

export type QueryPropertyFinancialAnalysisArgs = {
  slug: Scalars['String'];
};

export type QueryPropertyArgs = {
  slug: Scalars['String'];
};

export type QueryInvestedPropertiesArgs = {
  filter?: Maybe<InvestedPropertiesFilterType>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type QueryInvestedPropertiesV2Args = {
  filter?: Maybe<InvestedPropertiesFilterType>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_InvestedPropertiesMetaArgs = {
  filter?: Maybe<InvestedPropertiesFilterType>;
};

export type QueryInvestedPropertyRoundRepaymentStatisticsArgs = {
  propertyInvestmentRoundId: Scalars['ID'];
};

export type QueryPortfolioSizeMonthlyHistoryArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAverageYieldMonthlyHistoryArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryInvestmentsArgs = {
  slug: Scalars['String'];
};

export type QueryActiveSecondaryMarketOffersSumsArgs = {
  investmentRoundId: Scalars['ID'];
};

export type QuerySecondaryMarketItemsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  investmentRoundId?: Maybe<Scalars['ID']>;
};

export type QueryInvestmentRoundsSecondaryMarketInfosArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type QueryInvestmentRoundSecondaryMarketInfoArgs = {
  investmentRoundId: Scalars['String'];
};

export type Query_SecondaryMarketItemsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  investmentRoundId?: Maybe<Scalars['ID']>;
};

export type QuerySecondaryMarketBidsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_SecondaryMarketBidsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type QueryInvestmentRoundFeesArgs = {
  investmentRoundId: Scalars['String'];
};

export type QueryRepaymentScheduleArgs = {
  propertyInvestmentRoundId: Scalars['ID'];
};

export type QueryRelatedPropertiesArgs = {
  slug: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

/** Describes a property and its investment round related to another property by borrower */
export type RelatedPropertyObjectType = {
  __typename?: 'RelatedPropertyObjectType';
  /** Property id */
  id: Scalars['ID'];
  slug: Scalars['String'];
  /** Translated property name */
  name: Scalars['String'];
  countryIsoCode: Scalars['String'];
  city: Scalars['String'];
  /** Property primary photo */
  photo: PropertyPhotoObjectType;
  /** Property investment round id */
  propertyInvestmentRoundId: Scalars['ID'];
  annualPercentageYield: Scalars['Float'];
  /** Defines whether user can apply their current level to gain bonus yield. */
  bonusYieldEligible: Scalars['Boolean'];
  /** When are the investors paid back */
  investmentTermEnd?: Maybe<Scalars['Timestamp']>;
  investmentLengthInMonths: Scalars['Int'];
  investmentRoundRepaymentStatus: PropertyInvestmentRoundRepaymentStatus;
  /** User's total investment in this property investment round */
  investmentAmount: Scalars['Float'];
  /** Investment round currency */
  investmentCurrency: Scalars['String'];
  interestAmount: FloatMonetaryAmountObjectType;
  /** Due date of oldest unpaid installment in format YYYY-MM-DD. */
  oldestUnpaidInstallmentDate?: Maybe<Scalars['LocalDate']>;
  /** Due date of oldest unpaid installment in format YYYY-MM-DD with gracePeriod added. */
  oldestUnpaidInstallmentDateWithGracePeriod?: Maybe<Scalars['LocalDate']>;
  riskCategory: RiskCategory;
  investmentRoundStatus: PropertyInvestmentRoundStatus;
  level: PropertyInvestmentRoundLevel;
};

export type RepaymentScheduleObjectType = {
  __typename?: 'RepaymentScheduleObjectType';
  /** Due date of oldest unpaid installment in format YYYY-MM-DD. */
  oldestUnpaidInstallmentDate?: Maybe<Scalars['LocalDate']>;
  /** Due date of oldest unpaid installment in format YYYY-MM-DD with gracePeriod added. */
  oldestUnpaidInstallmentDateWithGracePeriod?: Maybe<Scalars['LocalDate']>;
};

/** Count of invested projects and sum of invested money for each repayment status. */
export type RepaymentStatisticsObjectType = {
  __typename?: 'RepaymentStatisticsObjectType';
  regular: PortfolioPartStatisticsObjectType;
  delayed: PortfolioPartStatisticsObjectType;
  collection: PortfolioPartStatisticsObjectType;
};

export enum RiskCategory {
  A = 'A',
  APlus = 'A_PLUS',
  AMinus = 'A_MINUS',
  B = 'B',
  BPlus = 'B_PLUS',
  BMinus = 'B_MINUS',
  C = 'C',
  CPlus = 'C_PLUS',
  CMinus = 'C_MINUS',
  D = 'D',
  Unknown = 'UNKNOWN',
}

export type SecondaryMarketBid = Node & {
  __typename?: 'SecondaryMarketBid';
  /** Primary identifier */
  id: Scalars['ID'];
  /** ID of the related secondary market offer */
  secondaryMarketOfferId: Scalars['ID'];
  property: SecondaryMarketItemPropertyInfo;
  /** Info about seller user */
  bidder: BasicUserInfoObjectType;
  bidAmount: FloatMonetaryAmountObjectType;
};

export type SecondaryMarketItem = Node & {
  __typename?: 'SecondaryMarketItem';
  /** Primary identifier */
  id: Scalars['ID'];
  property: SecondaryMarketItemPropertyInfo;
  /** Info about seller user */
  seller: BasicUserInfoObjectType;
  /** Currently available amount for investment (e.g. 100k CZK) */
  investmentAmountAvailable: Scalars['Float'];
  investmentCurrency: Scalars['String'];
  /** When are the investors paid back */
  investmentTermEnd: Scalars['Timestamp'];
  /** Annual percentage yield (e.g. 0.045 = 4,5 %) */
  annualPercentageYield: Scalars['Float'];
  /** Multiple of the `annualPercentageYield` and the period until `investmentTermEnd` plus `endPercentageBonus` */
  overallAdjustedYield: Scalars['Float'];
  /** Percentage bonus that is paid off at the end to all investment holders */
  endPercentageBonus: Scalars['Float'];
  level: SecondaryMarketItemLevel;
  /** Loan To Value refers to the ratio between the loan amount and the collateral value stated as a percentage. */
  loanToValue: Scalars['Float'];
};

export enum SecondaryMarketItemLevel {
  Standard = 'Standard',
  Premium = 'Premium',
  Vip = 'Vip',
}

export type SecondaryMarketItemPropertyInfo = Node & {
  __typename?: 'SecondaryMarketItemPropertyInfo';
  id: Scalars['ID'];
  city: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  countryIsoCode: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type SecondaryMarketOffer = Node & {
  __typename?: 'SecondaryMarketOffer';
  id: Scalars['ID'];
  initialOfferAmount: Scalars['Float'];
  remainingAmount: Scalars['Float'];
  minInvestment: Scalars['Float'];
};

/** Describes sums of amounts of secondary market offers. */
export type SecondaryMarketOffersSums = {
  __typename?: 'SecondaryMarketOffersSums';
  /** Sum of initialOfferAmount attribute of all related secondary market offers. */
  initialOfferAmountsSum: AmountWithCurrencyObjectType;
  /** Sum of remainingAmount attribute of all related secondary market offers. */
  remainingAmountsSum: AmountWithCurrencyObjectType;
  /** Represents the amount which user can offer, considering offer remaining amounts and active bid amounts. */
  availableForOfferingAmount: AmountWithCurrencyObjectType;
  /** Amount of offered investments including offer remaining amounts and active bid amounts. */
  currentlyOfferedAmount: AmountWithCurrencyObjectType;
};

export type ActiveSecondaryMarketRemainingOffersSumsQueryVariables = Exact<{
  investmentRoundId: Scalars['ID'];
}>;

export type ActiveSecondaryMarketRemainingOffersSumsQuery = { __typename?: 'Query' } & {
  activeSecondaryMarketOffersSums: { __typename?: 'SecondaryMarketOffersSums' } & {
    initialOfferAmountsSum: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
      AmountWithCurrencyObjectType,
      'amount' | 'currency'
    >;
    remainingAmountsSum: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
      AmountWithCurrencyObjectType,
      'amount' | 'currency'
    >;
    availableForOfferingAmount: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
      AmountWithCurrencyObjectType,
      'amount' | 'currency'
    >;
    currentlyOfferedAmount: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
      AmountWithCurrencyObjectType,
      'amount' | 'currency'
    >;
  };
};

export type AverageYieldMonthlyHistoryQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;

export type AverageYieldMonthlyHistoryQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'AverageYieldObjectType' } & Pick<AverageYieldObjectType, 'averageYield' | 'year' | 'month'>
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type CountInvestedPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type CountInvestedPropertiesQuery = { __typename?: 'Query' } & {
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type CountPageablePropertiesQueryVariables = Exact<{
  investmentAmountAvailable_eq?: Maybe<Scalars['Float']>;
  investmentAmountAvailable_gt?: Maybe<Scalars['Float']>;
  currentUsersTotalInvestment_gt?: Maybe<Scalars['Float']>;
  level_in?: Maybe<Array<PropertyInvestmentRoundLevel> | PropertyInvestmentRoundLevel>;
  type_in?: Maybe<Array<PropertyInvestmentRoundType> | PropertyInvestmentRoundType>;
  status_in?: Maybe<Array<PropertyInvestmentRoundStatus> | PropertyInvestmentRoundStatus>;
}>;

export type CountPageablePropertiesQuery = { __typename?: 'Query' } & {
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type CountSecondaryMarketBidsQueryVariables = Exact<{ [key: string]: never }>;

export type CountSecondaryMarketBidsQuery = { __typename?: 'Query' } & {
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type CountSecondaryMarketItemsQueryVariables = Exact<{ [key: string]: never }>;

export type CountSecondaryMarketItemsQuery = { __typename?: 'Query' } & {
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type InvestedPropertiesV2QueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<InvestedPropertiesFilterType>;
}>;

export type InvestedPropertiesV2Query = { __typename?: 'Query' } & {
  investedPropertiesV2: Array<
    { __typename?: 'InvestedPropertyObjectType' } & Pick<
      InvestedPropertyObjectType,
      | 'id'
      | 'propertyInvestmentRoundId'
      | 'investmentRoundType'
      | 'slug'
      | 'name'
      | 'suburb'
      | 'countryIsoCode'
      | 'city'
      | 'investmentCurrency'
      | 'investmentAmount'
      | 'annualPercentageYield'
      | 'investmentLengthInMonths'
      | 'timeLeft'
      | 'investmentTermEnd'
      | 'investmentRoundStatus'
      | 'investmentRoundRepaymentStatus'
      | 'investmentRoundLegacyEndedAt'
      | 'riskCategory'
      | 'bonusYieldEligible'
      | 'level'
      | 'oldestUnpaidInstallmentDate'
      | 'oldestUnpaidInstallmentDateWithGracePeriod'
    > & {
        photo: { __typename?: 'PropertyPhotoObjectType' } & Pick<PropertyPhotoObjectType, 'id' | 'isPrimary' | 'url'>;
        interestAmount: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
          FloatMonetaryAmountObjectType,
          'value' | 'currency'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type InvestedPropertyRoundRepaymentStatisticsQueryVariables = Exact<{
  propertyInvestmentRoundId: Scalars['ID'];
}>;

export type InvestedPropertyRoundRepaymentStatisticsQuery = { __typename?: 'Query' } & {
  investedPropertyRoundRepaymentStatistics: {
    __typename?: 'InvestedPropertyRoundRepaymentStatisticsObjectType';
  } & Pick<InvestedPropertyRoundRepaymentStatisticsObjectType, 'currentYield' | 'expectedYield'>;
};

export type InvestmentRoundFeesQueryVariables = Exact<{
  investmentRoundId: Scalars['String'];
}>;

export type InvestmentRoundFeesQuery = { __typename?: 'Query' } & {
  investmentRoundFees: { __typename?: 'PropertyInvestmentRoundFees' } & Pick<
    PropertyInvestmentRoundFees,
    'entryFee' | 'monthlyYieldPlatformFee' | 'discountedInterestRate'
  > & {
      interestSumValues?: Maybe<
        { __typename?: 'InterestSumValues' } & {
          settled: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
            FloatMonetaryAmountObjectType,
            'value' | 'currency'
          >;
          planned: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
            FloatMonetaryAmountObjectType,
            'value' | 'currency'
          >;
          overdue: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
            FloatMonetaryAmountObjectType,
            'value' | 'currency'
          >;
        }
      >;
    };
};

export type InvestmentRoundSecondaryMarketInfoQueryVariables = Exact<{
  investmentRoundId: Scalars['String'];
}>;

export type InvestmentRoundSecondaryMarketInfoQuery = { __typename?: 'Query' } & {
  investmentRoundSecondaryMarketInfo?: Maybe<
    { __typename?: 'InvestmentRoundSecondaryMarketInfo' } & Pick<
      InvestmentRoundSecondaryMarketInfo,
      | 'id'
      | 'numberOfInvestors'
      | 'offersCount'
      | 'timeLeft'
      | 'annualPercentageYield'
      | 'bonusYieldEligible'
      | 'level'
      | 'loanToValue'
      | 'currentUsersTotalInvestment'
      | 'repaymentStatus'
      | 'riskCategory'
    > & {
        property: { __typename?: 'SecondaryMarketItemPropertyInfo' } & Pick<
          SecondaryMarketItemPropertyInfo,
          'id' | 'city' | 'slug' | 'countryIsoCode' | 'name' | 'thumbnailUrl'
        >;
        remainingAmountsSum: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
      }
  >;
};

export type InvestmentsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type InvestmentsQuery = { __typename?: 'Query' } & {
  investments: Array<
    { __typename?: 'InvestmentObjectType' } & Pick<
      InvestmentObjectType,
      'id' | 'occurredAt' | 'canBeWithdrawn' | 'withdrawn'
    > & {
        amount: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
      }
  >;
};

export type PageableInvestmentRoundsSecondaryMarketInfosQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;

export type PageableInvestmentRoundsSecondaryMarketInfosQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'InvestmentRoundSecondaryMarketInfo' } & Pick<
      InvestmentRoundSecondaryMarketInfo,
      | 'id'
      | 'numberOfInvestors'
      | 'offersCount'
      | 'timeLeft'
      | 'annualPercentageYield'
      | 'bonusYieldEligible'
      | 'level'
      | 'loanToValue'
      | 'currentUsersTotalInvestment'
      | 'repaymentStatus'
      | 'riskCategory'
    > & {
        property: { __typename?: 'SecondaryMarketItemPropertyInfo' } & Pick<
          SecondaryMarketItemPropertyInfo,
          'id' | 'city' | 'countryIsoCode' | 'slug' | 'name' | 'thumbnailUrl'
        >;
        remainingAmountsSum: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type PageablePortfolioSizeMonthlyHistoryQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;

export type PageablePortfolioSizeMonthlyHistoryQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'MonthlyPortfolioSizeObjectType' } & Pick<MonthlyPortfolioSizeObjectType, 'year' | 'month'> & {
        portfolioSize: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type PageablePropertiesQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  investmentAmountAvailable_eq?: Maybe<Scalars['Float']>;
  investmentAmountAvailable_gt?: Maybe<Scalars['Float']>;
  currentUsersTotalInvestment_gt?: Maybe<Scalars['Float']>;
  level_in?: Maybe<Array<PropertyInvestmentRoundLevel> | PropertyInvestmentRoundLevel>;
  type_in?: Maybe<Array<PropertyInvestmentRoundType> | PropertyInvestmentRoundType>;
  status_in?: Maybe<Array<PropertyInvestmentRoundStatus> | PropertyInvestmentRoundStatus>;
}>;

export type PageablePropertiesQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'PropertyBaseObjectType' } & Pick<
      PropertyBaseObjectType,
      'id' | 'status' | 'slug' | 'name' | 'city' | 'suburb' | 'floorSpace' | 'countryIsoCode' | 'propertyLayout'
    > & {
        photos: Array<
          { __typename?: 'PropertyPhotoObjectType' } & Pick<PropertyPhotoObjectType, 'id' | 'isPrimary' | 'url'>
        >;
        currentInvestmentRound?: Maybe<
          { __typename?: 'PropertyInvestmentRoundBaseObjectType' } & Pick<
            PropertyInvestmentRoundBaseObjectType,
            | 'id'
            | 'investmentCurrency'
            | 'currentUsersTotalInvestment'
            | 'annualPercentageYield'
            | 'investmentTermEnd'
            | 'investmentAmountTotal'
            | 'investmentAmountAvailable'
            | 'numberOfInvestors'
            | 'bonusYieldEligible'
            | 'level'
            | 'adjustedYield'
            | 'endPercentageBonus'
            | 'investmentLengthInMonths'
            | 'availableForInvestmentTo'
            | 'type'
            | 'status'
            | 'contractVersionNumber'
            | 'loanToValue'
            | 'repaymentStatus'
            | 'riskCategory'
          >
        >;
        investmentRound: { __typename?: 'PropertyInvestmentRoundBaseObjectType' } & Pick<
          PropertyInvestmentRoundBaseObjectType,
          | 'id'
          | 'investmentCurrency'
          | 'currentUsersTotalInvestment'
          | 'annualPercentageYield'
          | 'investmentTermEnd'
          | 'investmentAmountTotal'
          | 'investmentAmountAvailable'
          | 'numberOfInvestors'
          | 'bonusYieldEligible'
          | 'level'
          | 'adjustedYield'
          | 'endPercentageBonus'
          | 'investmentLengthInMonths'
          | 'availableForInvestmentTo'
          | 'type'
          | 'status'
          | 'contractVersionNumber'
          | 'loanToValue'
          | 'repaymentStatus'
          | 'riskCategory'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type RelatedPropertiesQueryVariables = Exact<{
  slug: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;

export type RelatedPropertiesQuery = { __typename?: 'Query' } & {
  relatedProperties: Array<
    { __typename?: 'RelatedPropertyObjectType' } & Pick<
      RelatedPropertyObjectType,
      | 'annualPercentageYield'
      | 'id'
      | 'investmentAmount'
      | 'oldestUnpaidInstallmentDate'
      | 'oldestUnpaidInstallmentDateWithGracePeriod'
      | 'city'
      | 'countryIsoCode'
      | 'investmentCurrency'
      | 'investmentLengthInMonths'
      | 'investmentTermEnd'
      | 'propertyInvestmentRoundId'
      | 'investmentRoundStatus'
      | 'investmentRoundRepaymentStatus'
      | 'name'
      | 'slug'
      | 'riskCategory'
      | 'bonusYieldEligible'
      | 'level'
    > & {
        photo: { __typename?: 'PropertyPhotoObjectType' } & Pick<PropertyPhotoObjectType, 'id' | 'isPrimary' | 'url'>;
        interestAmount: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
          FloatMonetaryAmountObjectType,
          'currency' | 'value'
        >;
      }
  >;
};

export type PortfolioStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioStatisticsQuery = { __typename?: 'Query' } & {
  portfolioStatistics: { __typename?: 'PortfolioStatisticsObjectType' } & Pick<
    PortfolioStatisticsObjectType,
    | 'portfolioSize'
    | 'propertiesCount'
    | 'activePropertiesCount'
    | 'citiesCount'
    | 'currentAverageYield'
    | 'currentAverageDiscountedInterestRate'
  > & {
      repaymentStatistics: { __typename?: 'RepaymentStatisticsObjectType' } & {
        regular: { __typename?: 'PortfolioPartStatisticsObjectType' } & Pick<
          PortfolioPartStatisticsObjectType,
          'investedAmount' | 'propertiesCount'
        >;
        delayed: { __typename?: 'PortfolioPartStatisticsObjectType' } & Pick<
          PortfolioPartStatisticsObjectType,
          'investedAmount' | 'propertiesCount'
        >;
        collection: { __typename?: 'PortfolioPartStatisticsObjectType' } & Pick<
          PortfolioPartStatisticsObjectType,
          'investedAmount' | 'propertiesCount'
        >;
      };
      currentAndExpectedYieldStatisticsForActualMonth: {
        __typename?: 'CurrentAndExpectedYieldStatisticsForActualMonthObjectType';
      } & Pick<CurrentAndExpectedYieldStatisticsForActualMonthObjectType, 'expectedYield' | 'repaidYield'>;
    };
};

export type PropertyQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PropertyQuery = { __typename?: 'Query' } & {
  property: { __typename?: 'PropertyObjectType' } & Pick<
    PropertyObjectType,
    | 'id'
    | 'status'
    | 'name'
    | 'description'
    | 'city'
    | 'suburb'
    | 'propertyType'
    | 'propertyLayout'
    | 'countryIsoCode'
    | 'floorSpace'
    | 'occupied'
  > & {
      location: { __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>;
      photos: Array<
        { __typename?: 'PropertyPhotoObjectType' } & Pick<PropertyPhotoObjectType, 'id' | 'isPrimary' | 'url'>
      >;
      currentInvestmentRound?: Maybe<
        { __typename?: 'PropertyInvestmentRoundObjectType' } & Pick<
          PropertyInvestmentRoundObjectType,
          | 'id'
          | 'legalId'
          | 'investmentAmountTotal'
          | 'investmentAmountAvailable'
          | 'investmentCurrency'
          | 'currentUsersTotalInvestment'
          | 'investmentTermEnd'
          | 'annualPercentageYield'
          | 'minInvestment'
          | 'numberOfInvestors'
          | 'limitPerUser'
          | 'bonusYieldEligible'
          | 'level'
          | 'loanToValue'
          | 'collateral'
          | 'purpose'
          | 'overallAdjustedYield'
          | 'adjustedYield'
          | 'endPercentageBonus'
          | 'investmentLengthInMonths'
          | 'availableForInvestmentTo'
          | 'type'
          | 'status'
          | 'contractVersionNumber'
          | 'legacyEndedAt'
          | 'repaymentStatus'
        > & {
            borrower: { __typename?: 'PropertyBorrower' } & Pick<
              PropertyBorrower,
              'companyName' | 'description' | 'registerEntry' | 'companyIdentifier'
            > & { address: { __typename?: 'Address' } & Pick<Address, 'street' | 'city' | 'zip' | 'countryIsoCode'> };
            borrowers: Array<
              { __typename?: 'PropertyBorrower' } & Pick<
                PropertyBorrower,
                'companyName' | 'description' | 'registerEntry' | 'companyIdentifier'
              > & { address: { __typename?: 'Address' } & Pick<Address, 'street' | 'city' | 'zip' | 'countryIsoCode'> }
            >;
            activeSecondaryMarketOffers: Array<
              { __typename?: 'SecondaryMarketOffer' } & Pick<
                SecondaryMarketOffer,
                'id' | 'initialOfferAmount' | 'remainingAmount' | 'minInvestment'
              >
            >;
          }
      >;
      investmentRound: { __typename?: 'PropertyInvestmentRoundObjectType' } & Pick<
        PropertyInvestmentRoundObjectType,
        | 'id'
        | 'legalId'
        | 'investmentAmountTotal'
        | 'investmentAmountAvailable'
        | 'investmentCurrency'
        | 'currentUsersTotalInvestment'
        | 'investmentTermEnd'
        | 'annualPercentageYield'
        | 'minInvestment'
        | 'numberOfInvestors'
        | 'limitPerUser'
        | 'bonusYieldEligible'
        | 'level'
        | 'loanToValue'
        | 'collateral'
        | 'purpose'
        | 'overallAdjustedYield'
        | 'adjustedYield'
        | 'endPercentageBonus'
        | 'investmentLengthInMonths'
        | 'availableForInvestmentTo'
        | 'type'
        | 'status'
        | 'contractVersionNumber'
        | 'legacyEndedAt'
        | 'repaymentStatus'
        | 'riskCategory'
      > & {
          borrower: { __typename?: 'PropertyBorrower' } & Pick<
            PropertyBorrower,
            'companyName' | 'description' | 'registerEntry' | 'companyIdentifier'
          > & { address: { __typename?: 'Address' } & Pick<Address, 'street' | 'city' | 'zip' | 'countryIsoCode'> };
          borrowers: Array<
            { __typename?: 'PropertyBorrower' } & Pick<
              PropertyBorrower,
              'companyName' | 'description' | 'registerEntry' | 'companyIdentifier'
            > & { address: { __typename?: 'Address' } & Pick<Address, 'street' | 'city' | 'zip' | 'countryIsoCode'> }
          >;
          activeSecondaryMarketOffers: Array<
            { __typename?: 'SecondaryMarketOffer' } & Pick<
              SecondaryMarketOffer,
              'id' | 'initialOfferAmount' | 'remainingAmount' | 'minInvestment'
            >
          >;
        };
    };
};

export type PropertyFinancialAnalysisQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PropertyFinancialAnalysisQuery = { __typename?: 'Query' } & {
  propertyFinancialAnalysis?: Maybe<
    { __typename?: 'PropertyFinancialAnalysisObjectType' } & Pick<
      PropertyFinancialAnalysisObjectType,
      'description'
    > & {
        financialData: Array<
          { __typename?: 'PropertyFinancialDataYearObjectType' } & Pick<PropertyFinancialDataYearObjectType, 'year'> & {
              values: Array<
                { __typename?: 'PropertyFinancialDataObjectType' } & Pick<
                  PropertyFinancialDataObjectType,
                  'metricName' | 'metricValue' | 'unit'
                >
              >;
            }
        >;
      }
  >;
};

export type PropertyInvestmentRoundActivityQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PropertyInvestmentRoundActivityQuery = { __typename?: 'Query' } & {
  property: { __typename?: 'PropertyObjectType' } & {
    investmentRound: { __typename?: 'PropertyInvestmentRoundObjectType' } & Pick<
      PropertyInvestmentRoundObjectType,
      'id'
    > & {
        activity: Array<
          { __typename?: 'PropertyInvestmentRoundActivityObjectType' } & Pick<
            PropertyInvestmentRoundActivityObjectType,
            'id' | 'source' | 'type' | 'occurredAt'
          > & {
              description: Array<
                { __typename?: 'PropertyInvestmentRoundActivityDescriptionObjectType' } & Pick<
                  PropertyInvestmentRoundActivityDescriptionObjectType,
                  'lang' | 'description'
                >
              >;
            }
        >;
      };
  };
};

export type RepaymentScheduleQueryVariables = Exact<{
  propertyInvestmentRoundId: Scalars['ID'];
}>;

export type RepaymentScheduleQuery = { __typename?: 'Query' } & {
  repaymentSchedule: { __typename?: 'RepaymentScheduleObjectType' } & Pick<
    RepaymentScheduleObjectType,
    'oldestUnpaidInstallmentDate' | 'oldestUnpaidInstallmentDateWithGracePeriod'
  >;
};

export type SecondaryMarketBidsQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;

export type SecondaryMarketBidsQuery = { __typename?: 'Query' } & {
  secondaryMarketBids: Array<
    { __typename?: 'SecondaryMarketBid' } & Pick<SecondaryMarketBid, 'id' | 'secondaryMarketOfferId'> & {
        property: { __typename?: 'SecondaryMarketItemPropertyInfo' } & Pick<
          SecondaryMarketItemPropertyInfo,
          'id' | 'city' | 'countryIsoCode' | 'name' | 'slug' | 'thumbnailUrl'
        >;
        bidAmount: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
          FloatMonetaryAmountObjectType,
          'value' | 'currency'
        >;
        bidder: { __typename?: 'BasicUserInfoObjectType' } & Pick<
          BasicUserInfoObjectType,
          'firstName' | 'lastName' | 'companyName'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type SecondaryMarketItemsQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  investmentRoundId?: Maybe<Scalars['ID']>;
}>;

export type SecondaryMarketItemsQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'SecondaryMarketItem' } & Pick<
      SecondaryMarketItem,
      | 'id'
      | 'investmentAmountAvailable'
      | 'investmentCurrency'
      | 'investmentTermEnd'
      | 'annualPercentageYield'
      | 'overallAdjustedYield'
      | 'endPercentageBonus'
      | 'level'
      | 'loanToValue'
    > & {
        property: { __typename?: 'SecondaryMarketItemPropertyInfo' } & Pick<
          SecondaryMarketItemPropertyInfo,
          'id' | 'city' | 'countryIsoCode' | 'name' | 'slug' | 'thumbnailUrl'
        >;
        seller: { __typename?: 'BasicUserInfoObjectType' } & Pick<
          BasicUserInfoObjectType,
          'firstName' | 'lastName' | 'companyName'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export const ActiveSecondaryMarketRemainingOffersSumsDocument = gql`
  query ActiveSecondaryMarketRemainingOffersSums($investmentRoundId: ID!) {
    activeSecondaryMarketOffersSums(investmentRoundId: $investmentRoundId) {
      initialOfferAmountsSum {
        amount
        currency
      }
      remainingAmountsSum {
        amount
        currency
      }
      availableForOfferingAmount {
        amount
        currency
      }
      currentlyOfferedAmount {
        amount
        currency
      }
    }
  }
`;
export const AverageYieldMonthlyHistoryDocument = gql`
  query AverageYieldMonthlyHistory($page: Int!, $perPage: Int!) {
    items: averageYieldMonthlyHistory(page: $page, perPage: $perPage) {
      averageYield
      year
      month
    }
    total: _averageYieldMonthlyHistoryMeta {
      count
    }
  }
`;
export const CountInvestedPropertiesDocument = gql`
  query CountInvestedProperties {
    total: _investedPropertiesMeta {
      count
    }
  }
`;
export const CountPageablePropertiesDocument = gql`
  query CountPageableProperties(
    $investmentAmountAvailable_eq: Float
    $investmentAmountAvailable_gt: Float
    $currentUsersTotalInvestment_gt: Float
    $level_in: [PropertyInvestmentRoundLevel!]
    $type_in: [PropertyInvestmentRoundType!]
    $status_in: [PropertyInvestmentRoundStatus!]
  ) {
    total: _allPropertiesMeta(
      currentInvestmentRoundFilter: {
        investmentAmountAvailable_eq: $investmentAmountAvailable_eq
        investmentAmountAvailable_gt: $investmentAmountAvailable_gt
        currentUsersTotalInvestment_gt: $currentUsersTotalInvestment_gt
        level_in: $level_in
        type_in: $type_in
        status_in: $status_in
      }
    ) {
      count
    }
  }
`;
export const CountSecondaryMarketBidsDocument = gql`
  query CountSecondaryMarketBids {
    total: _secondaryMarketBidsMeta {
      count
    }
  }
`;
export const CountSecondaryMarketItemsDocument = gql`
  query CountSecondaryMarketItems {
    total: _investmentRoundsSecondaryMarketInfosMeta {
      count
    }
  }
`;
export const InvestedPropertiesV2Document = gql`
  query InvestedPropertiesV2(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String = "ASC"
    $filter: InvestedPropertiesFilterType
  ) {
    investedPropertiesV2(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      propertyInvestmentRoundId
      investmentRoundType
      slug
      name
      suburb
      countryIsoCode
      city
      photo {
        id
        isPrimary
        url
      }
      investmentCurrency
      investmentAmount
      annualPercentageYield
      investmentLengthInMonths
      timeLeft
      investmentTermEnd
      investmentRoundStatus
      investmentRoundRepaymentStatus
      investmentRoundLegacyEndedAt
      riskCategory
      bonusYieldEligible
      level
      interestAmount {
        value
        currency
      }
      oldestUnpaidInstallmentDate
      oldestUnpaidInstallmentDateWithGracePeriod
    }
    total: _investedPropertiesMeta {
      count
    }
  }
`;
export const InvestedPropertyRoundRepaymentStatisticsDocument = gql`
  query InvestedPropertyRoundRepaymentStatistics($propertyInvestmentRoundId: ID!) {
    investedPropertyRoundRepaymentStatistics(propertyInvestmentRoundId: $propertyInvestmentRoundId) {
      currentYield
      expectedYield
    }
  }
`;
export const InvestmentRoundFeesDocument = gql`
  query InvestmentRoundFees($investmentRoundId: String!) {
    investmentRoundFees(investmentRoundId: $investmentRoundId) {
      interestSumValues {
        settled {
          value
          currency
        }
        planned {
          value
          currency
        }
        overdue {
          value
          currency
        }
      }
      entryFee
      monthlyYieldPlatformFee
      discountedInterestRate
    }
  }
`;
export const InvestmentRoundSecondaryMarketInfoDocument = gql`
  query InvestmentRoundSecondaryMarketInfo($investmentRoundId: String!) {
    investmentRoundSecondaryMarketInfo(investmentRoundId: $investmentRoundId) {
      id
      property {
        id
        city
        slug
        countryIsoCode
        name
        thumbnailUrl
      }
      numberOfInvestors
      offersCount
      remainingAmountsSum {
        amount
        currency
      }
      timeLeft
      annualPercentageYield
      bonusYieldEligible
      level
      loanToValue
      currentUsersTotalInvestment
      repaymentStatus
      riskCategory
    }
  }
`;
export const InvestmentsDocument = gql`
  query Investments($slug: String!) {
    investments(slug: $slug) {
      id
      occurredAt
      amount {
        amount
        currency
      }
      canBeWithdrawn
      withdrawn
    }
  }
`;
export const PageableInvestmentRoundsSecondaryMarketInfosDocument = gql`
  query PageableInvestmentRoundsSecondaryMarketInfos(
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
  ) {
    items: investmentRoundsSecondaryMarketInfos(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      id
      property {
        id
        city
        countryIsoCode
        slug
        name
        thumbnailUrl
      }
      numberOfInvestors
      offersCount
      remainingAmountsSum {
        amount
        currency
      }
      timeLeft
      annualPercentageYield
      bonusYieldEligible
      level
      loanToValue
      currentUsersTotalInvestment
      repaymentStatus
      riskCategory
    }
    total: _investmentRoundsSecondaryMarketInfosMeta {
      count
    }
  }
`;
export const PageablePortfolioSizeMonthlyHistoryDocument = gql`
  query PageablePortfolioSizeMonthlyHistory($page: Int!, $perPage: Int!) {
    items: portfolioSizeMonthlyHistory(page: $page, perPage: $perPage) {
      year
      month
      portfolioSize {
        amount
        currency
      }
    }
    total: _portfolioSizeMonthlyHistoryMeta {
      count
    }
  }
`;
export const PageablePropertiesDocument = gql`
  query PageableProperties(
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
    $investmentAmountAvailable_eq: Float
    $investmentAmountAvailable_gt: Float
    $currentUsersTotalInvestment_gt: Float
    $level_in: [PropertyInvestmentRoundLevel!]
    $type_in: [PropertyInvestmentRoundType!]
    $status_in: [PropertyInvestmentRoundStatus!]
  ) {
    items: allProperties(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      currentInvestmentRoundFilter: {
        investmentAmountAvailable_eq: $investmentAmountAvailable_eq
        investmentAmountAvailable_gt: $investmentAmountAvailable_gt
        currentUsersTotalInvestment_gt: $currentUsersTotalInvestment_gt
        level_in: $level_in
        type_in: $type_in
        status_in: $status_in
      }
    ) {
      id
      status
      slug
      name
      city
      suburb
      floorSpace
      countryIsoCode
      propertyLayout
      photos {
        id
        isPrimary
        url
      }
      currentInvestmentRound {
        id
        investmentCurrency
        currentUsersTotalInvestment
        annualPercentageYield
        investmentTermEnd
        investmentAmountTotal
        investmentAmountAvailable
        numberOfInvestors
        bonusYieldEligible
        level
        adjustedYield
        endPercentageBonus
        investmentLengthInMonths
        availableForInvestmentTo
        type
        status
        contractVersionNumber
        loanToValue
        repaymentStatus
        riskCategory
      }
      investmentRound {
        id
        investmentCurrency
        currentUsersTotalInvestment
        annualPercentageYield
        investmentTermEnd
        investmentAmountTotal
        investmentAmountAvailable
        numberOfInvestors
        bonusYieldEligible
        level
        adjustedYield
        endPercentageBonus
        investmentLengthInMonths
        availableForInvestmentTo
        type
        status
        contractVersionNumber
        loanToValue
        repaymentStatus
        riskCategory
      }
    }
    total: _allPropertiesMeta(
      currentInvestmentRoundFilter: {
        investmentAmountAvailable_eq: $investmentAmountAvailable_eq
        investmentAmountAvailable_gt: $investmentAmountAvailable_gt
        currentUsersTotalInvestment_gt: $currentUsersTotalInvestment_gt
        level_in: $level_in
        type_in: $type_in
        status_in: $status_in
      }
    ) {
      count
    }
  }
`;
export const RelatedPropertiesDocument = gql`
  query RelatedProperties($slug: String!, $page: Int, $perPage: Int) {
    relatedProperties(slug: $slug, page: $page, perPage: $perPage) {
      annualPercentageYield
      id
      investmentAmount
      oldestUnpaidInstallmentDate
      oldestUnpaidInstallmentDateWithGracePeriod
      photo {
        id
        isPrimary
        url
      }
      city
      countryIsoCode
      investmentCurrency
      investmentLengthInMonths
      investmentTermEnd
      propertyInvestmentRoundId
      interestAmount {
        currency
        value
      }
      investmentRoundStatus
      investmentRoundRepaymentStatus
      name
      slug
      riskCategory
      bonusYieldEligible
      level
    }
  }
`;
export const PortfolioStatisticsDocument = gql`
  query PortfolioStatistics {
    portfolioStatistics {
      portfolioSize
      propertiesCount
      activePropertiesCount
      citiesCount
      currentAverageYield
      repaymentStatistics {
        regular {
          investedAmount
          propertiesCount
        }
        delayed {
          investedAmount
          propertiesCount
        }
        collection {
          investedAmount
          propertiesCount
        }
      }
      currentAndExpectedYieldStatisticsForActualMonth {
        expectedYield
        repaidYield
      }
      currentAverageDiscountedInterestRate
    }
  }
`;
export const PropertyDocument = gql`
  query Property($slug: String!) {
    property(slug: $slug) {
      id
      status
      name
      description
      city
      suburb
      propertyType
      propertyLayout
      countryIsoCode
      floorSpace
      location {
        lat
        long
      }
      photos {
        id
        isPrimary
        url
      }
      occupied
      currentInvestmentRound {
        id
        legalId
        investmentAmountTotal
        investmentAmountAvailable
        investmentCurrency
        currentUsersTotalInvestment
        investmentTermEnd
        annualPercentageYield
        minInvestment
        numberOfInvestors
        limitPerUser
        bonusYieldEligible
        level
        loanToValue
        collateral
        purpose
        overallAdjustedYield
        adjustedYield
        endPercentageBonus
        borrower {
          companyName
          description
          registerEntry
          companyIdentifier
          address {
            street
            city
            zip
            countryIsoCode
          }
        }
        borrowers {
          companyName
          description
          registerEntry
          companyIdentifier
          address {
            street
            city
            zip
            countryIsoCode
          }
        }
        investmentLengthInMonths
        availableForInvestmentTo
        type
        status
        contractVersionNumber
        activeSecondaryMarketOffers {
          id
          initialOfferAmount
          remainingAmount
          minInvestment
        }
        loanToValue
        collateral
        legacyEndedAt
        repaymentStatus
      }
      investmentRound {
        id
        legalId
        investmentAmountTotal
        investmentAmountAvailable
        investmentCurrency
        currentUsersTotalInvestment
        investmentTermEnd
        annualPercentageYield
        minInvestment
        numberOfInvestors
        limitPerUser
        bonusYieldEligible
        level
        loanToValue
        collateral
        purpose
        overallAdjustedYield
        adjustedYield
        endPercentageBonus
        borrower {
          companyName
          description
          registerEntry
          companyIdentifier
          address {
            street
            city
            zip
            countryIsoCode
          }
        }
        borrowers {
          companyName
          description
          registerEntry
          companyIdentifier
          address {
            street
            city
            zip
            countryIsoCode
          }
        }
        investmentLengthInMonths
        availableForInvestmentTo
        type
        status
        contractVersionNumber
        activeSecondaryMarketOffers {
          id
          initialOfferAmount
          remainingAmount
          minInvestment
        }
        loanToValue
        collateral
        legacyEndedAt
        repaymentStatus
        riskCategory
      }
    }
  }
`;
export const PropertyFinancialAnalysisDocument = gql`
  query PropertyFinancialAnalysis($slug: String!) {
    propertyFinancialAnalysis(slug: $slug) {
      description
      financialData {
        year
        values {
          metricName
          metricValue
          unit
        }
      }
    }
  }
`;
export const PropertyInvestmentRoundActivityDocument = gql`
  query PropertyInvestmentRoundActivity($slug: String!) {
    property(slug: $slug) {
      investmentRound {
        id
        activity {
          id
          source
          type
          occurredAt
          description {
            lang
            description
          }
        }
      }
    }
  }
`;
export const RepaymentScheduleDocument = gql`
  query RepaymentSchedule($propertyInvestmentRoundId: ID!) {
    repaymentSchedule(propertyInvestmentRoundId: $propertyInvestmentRoundId) {
      oldestUnpaidInstallmentDate
      oldestUnpaidInstallmentDateWithGracePeriod
    }
  }
`;
export const SecondaryMarketBidsDocument = gql`
  query SecondaryMarketBids($page: Int!, $perPage: Int!) {
    secondaryMarketBids(page: $page, perPage: $perPage) {
      id
      property {
        id
        city
        countryIsoCode
        name
        slug
        thumbnailUrl
      }
      secondaryMarketOfferId
      bidAmount {
        value
        currency
      }
      bidder {
        firstName
        lastName
        companyName
      }
    }
    total: _secondaryMarketBidsMeta {
      count
    }
  }
`;
export const SecondaryMarketItemsDocument = gql`
  query SecondaryMarketItems(
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
    $investmentRoundId: ID
  ) {
    items: secondaryMarketItems(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      investmentRoundId: $investmentRoundId
    ) {
      id
      property {
        id
        city
        countryIsoCode
        name
        slug
        thumbnailUrl
      }
      investmentAmountAvailable
      investmentCurrency
      investmentTermEnd
      annualPercentageYield
      overallAdjustedYield
      endPercentageBonus
      level
      loanToValue
      seller {
        firstName
        lastName
        companyName
      }
    }
    total: _secondaryMarketItemsMeta(investmentRoundId: $investmentRoundId) {
      count
    }
  }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    ActiveSecondaryMarketRemainingOffersSums(
      variables: ActiveSecondaryMarketRemainingOffersSumsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ActiveSecondaryMarketRemainingOffersSumsQuery> {
      return withWrapper(() =>
        client.request<ActiveSecondaryMarketRemainingOffersSumsQuery>(
          ActiveSecondaryMarketRemainingOffersSumsDocument,
          variables,
          requestHeaders
        )
      );
    },
    AverageYieldMonthlyHistory(
      variables: AverageYieldMonthlyHistoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AverageYieldMonthlyHistoryQuery> {
      return withWrapper(() =>
        client.request<AverageYieldMonthlyHistoryQuery>(AverageYieldMonthlyHistoryDocument, variables, requestHeaders)
      );
    },
    CountInvestedProperties(
      variables?: CountInvestedPropertiesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CountInvestedPropertiesQuery> {
      return withWrapper(() =>
        client.request<CountInvestedPropertiesQuery>(CountInvestedPropertiesDocument, variables, requestHeaders)
      );
    },
    CountPageableProperties(
      variables?: CountPageablePropertiesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CountPageablePropertiesQuery> {
      return withWrapper(() =>
        client.request<CountPageablePropertiesQuery>(CountPageablePropertiesDocument, variables, requestHeaders)
      );
    },
    CountSecondaryMarketBids(
      variables?: CountSecondaryMarketBidsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CountSecondaryMarketBidsQuery> {
      return withWrapper(() =>
        client.request<CountSecondaryMarketBidsQuery>(CountSecondaryMarketBidsDocument, variables, requestHeaders)
      );
    },
    CountSecondaryMarketItems(
      variables?: CountSecondaryMarketItemsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CountSecondaryMarketItemsQuery> {
      return withWrapper(() =>
        client.request<CountSecondaryMarketItemsQuery>(CountSecondaryMarketItemsDocument, variables, requestHeaders)
      );
    },
    InvestedPropertiesV2(
      variables?: InvestedPropertiesV2QueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvestedPropertiesV2Query> {
      return withWrapper(() =>
        client.request<InvestedPropertiesV2Query>(InvestedPropertiesV2Document, variables, requestHeaders)
      );
    },
    InvestedPropertyRoundRepaymentStatistics(
      variables: InvestedPropertyRoundRepaymentStatisticsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvestedPropertyRoundRepaymentStatisticsQuery> {
      return withWrapper(() =>
        client.request<InvestedPropertyRoundRepaymentStatisticsQuery>(
          InvestedPropertyRoundRepaymentStatisticsDocument,
          variables,
          requestHeaders
        )
      );
    },
    InvestmentRoundFees(
      variables: InvestmentRoundFeesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvestmentRoundFeesQuery> {
      return withWrapper(() =>
        client.request<InvestmentRoundFeesQuery>(InvestmentRoundFeesDocument, variables, requestHeaders)
      );
    },
    InvestmentRoundSecondaryMarketInfo(
      variables: InvestmentRoundSecondaryMarketInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvestmentRoundSecondaryMarketInfoQuery> {
      return withWrapper(() =>
        client.request<InvestmentRoundSecondaryMarketInfoQuery>(
          InvestmentRoundSecondaryMarketInfoDocument,
          variables,
          requestHeaders
        )
      );
    },
    Investments(
      variables: InvestmentsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvestmentsQuery> {
      return withWrapper(() => client.request<InvestmentsQuery>(InvestmentsDocument, variables, requestHeaders));
    },
    PageableInvestmentRoundsSecondaryMarketInfos(
      variables: PageableInvestmentRoundsSecondaryMarketInfosQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PageableInvestmentRoundsSecondaryMarketInfosQuery> {
      return withWrapper(() =>
        client.request<PageableInvestmentRoundsSecondaryMarketInfosQuery>(
          PageableInvestmentRoundsSecondaryMarketInfosDocument,
          variables,
          requestHeaders
        )
      );
    },
    PageablePortfolioSizeMonthlyHistory(
      variables: PageablePortfolioSizeMonthlyHistoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PageablePortfolioSizeMonthlyHistoryQuery> {
      return withWrapper(() =>
        client.request<PageablePortfolioSizeMonthlyHistoryQuery>(
          PageablePortfolioSizeMonthlyHistoryDocument,
          variables,
          requestHeaders
        )
      );
    },
    PageableProperties(
      variables: PageablePropertiesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PageablePropertiesQuery> {
      return withWrapper(() =>
        client.request<PageablePropertiesQuery>(PageablePropertiesDocument, variables, requestHeaders)
      );
    },
    RelatedProperties(
      variables: RelatedPropertiesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RelatedPropertiesQuery> {
      return withWrapper(() =>
        client.request<RelatedPropertiesQuery>(RelatedPropertiesDocument, variables, requestHeaders)
      );
    },
    PortfolioStatistics(
      variables?: PortfolioStatisticsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PortfolioStatisticsQuery> {
      return withWrapper(() =>
        client.request<PortfolioStatisticsQuery>(PortfolioStatisticsDocument, variables, requestHeaders)
      );
    },
    Property(variables: PropertyQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<PropertyQuery> {
      return withWrapper(() => client.request<PropertyQuery>(PropertyDocument, variables, requestHeaders));
    },
    PropertyFinancialAnalysis(
      variables: PropertyFinancialAnalysisQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PropertyFinancialAnalysisQuery> {
      return withWrapper(() =>
        client.request<PropertyFinancialAnalysisQuery>(PropertyFinancialAnalysisDocument, variables, requestHeaders)
      );
    },
    PropertyInvestmentRoundActivity(
      variables: PropertyInvestmentRoundActivityQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PropertyInvestmentRoundActivityQuery> {
      return withWrapper(() =>
        client.request<PropertyInvestmentRoundActivityQuery>(
          PropertyInvestmentRoundActivityDocument,
          variables,
          requestHeaders
        )
      );
    },
    RepaymentSchedule(
      variables: RepaymentScheduleQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RepaymentScheduleQuery> {
      return withWrapper(() =>
        client.request<RepaymentScheduleQuery>(RepaymentScheduleDocument, variables, requestHeaders)
      );
    },
    SecondaryMarketBids(
      variables: SecondaryMarketBidsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SecondaryMarketBidsQuery> {
      return withWrapper(() =>
        client.request<SecondaryMarketBidsQuery>(SecondaryMarketBidsDocument, variables, requestHeaders)
      );
    },
    SecondaryMarketItems(
      variables: SecondaryMarketItemsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SecondaryMarketItemsQuery> {
      return withWrapper(() =>
        client.request<SecondaryMarketItemsQuery>(SecondaryMarketItemsDocument, variables, requestHeaders)
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
